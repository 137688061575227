import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import { FaInstagram, FaYoutubeSquare, FaTwitterSquare, FaLinkedin, FaGithubSquare } from "react-icons/fa";
import Carousel from 'react-bootstrap/Carousel';
import AmpInstagram from '../../../components/AmpInstagram';

import { useState } from 'react';

import './styles.css';

export default class HomeSmallSize extends Component {
  render() {
      return(
        <Container fluid className="fill-height">
        <Navbar fixed="top" className="customFixedNavBar" bg="dark" variant="dark">
           <Navbar.Brand>
               <img
                alt=""
                src="/lab_ninjas_logo_transparent.png"
                width="40"
                height="40"
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>

          </Navbar>
          <Row className="justify-content-center intro">
            <div className="disclaimer">
                Remember: it's just for fun :)
            </div>
          </Row>
            <Row className="justify-content-center social-media-small mt-auto terms-and-conditions-panel">
                <Card className="mb-3 terms-and-conditions-card">
                    <Card.Body>
                        <Card.Title>Terms and Conditions</Card.Title>
                        <Card.Text>
                            <strong>1. Acceptance of Terms</strong><br />
                            By accessing or using this game, you agree to these terms and conditions. If you do not agree, please do not play.<br /><br />

                            <strong>2. User Responsibilities</strong>
                            <ul>
                                <li>You are responsible for securing your account information.</li>
                                <li>Cheating, hacking, or exploiting bugs is strictly prohibited.</li>
                            </ul>

                            <strong>3. Intellectual Property</strong><br />
                            All content, including graphics, code, and gameplay mechanics, is owned by <strong>ForFun Lab</strong>. Unauthorized reproduction is prohibited.<br /><br />

                            <strong>4. Limitation of Liability</strong><br />
                            The game is provided "as-is" without warranties. We are not responsible for any loss or damage resulting from its use.<br /><br />

                            <strong>5. In-App Purchases</strong><br />
                            All in-app purchases are final and non-refundable unless required by law.<br /><br />

                            <strong>6. Privacy</strong>
                            <p>
                                We value your privacy. The following information is collected and managed responsibly:
                            </p>
                            <ul>
                                <li>**Personal Information:** Your name, email address, and other account details provided during registration.</li>
                                <li>**Game Data:** Gameplay statistics, achievements, and in-game purchases.</li>
                                <li>**Device Information:** Device type, operating system, and usage data.</li>
                            </ul>
                            <p>
                                We use this information to enhance your gaming experience, maintain game functionality, and improve our services. Your data will not be shared with third parties except as required by law or for game-related operations (e.g., payment processing or cloud hosting).
                            </p>
                            <p>
                                You can contact us at contact.forfunlab@gmail.com to request data deletion or for any privacy-related concerns.
                            </p><br />

                            <strong>7. Termination</strong><br />
                            We reserve the right to suspend or terminate accounts for violating these terms.<br /><br />

                            <strong>8. Changes to Terms</strong><br />
                            We may update these terms at any time. Continued use of the game constitutes acceptance of the updated terms.<br /><br />

                            <strong>9. Contact</strong><br />
                            If you have questions, please contact us at <a href="mailto:contact.forfunlab@gmail.com">contact.forfunlab@gmail.com</a>.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
      );
  }
}