import './App.css';

import Sidebar from './components/Sidebar';

import Header from './components/Header';

import PixelArtRobotics from './components/PixelArtRobotics';

import Chemistry from './components/Chemistry';

import About from './components/About';

import Home from './pages/home'

import LabNinjas from './pages/lab-ninjas-home'

import LabNinjasTermsAndConditions from './pages/lab-ninjas-terms-and-conditions'

import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/lab-ninjas" element={<LabNinjas />} />
            <Route path="/lab-ninjas/terms-and-conditions" element={<LabNinjasTermsAndConditions />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
