import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaInstagram, FaYoutubeSquare, FaTwitterSquare, FaLinkedin, FaGithubSquare } from "react-icons/fa";
import Carousel from 'react-bootstrap/Carousel';
import AmpInstagram from '../../../components/AmpInstagram';

import { useState } from 'react';

import './styles.css';

export default class HomeNormalSize extends Component {
  render() {
      return(
        <Container fluid className="fill-height">
        <Navbar fixed="top" className="customNavBar" bg="dark" variant="dark">
           <Navbar.Brand>
               <img
                alt=""
                src="/lab_ninjas_logo_transparent.png"
                width="50"
                height="50"
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Row className="fill-height">
            <Col xs={5} md={3} className="panel left-panel">
                <Row className="align-content-between fill-height">
                    <Row className="justify-content-md-center">
                        <div className="disclaimer">
                            Remember: it's just for fun :)
                        </div>
                       </Row>
                    <Row className="justify-content-md-center social-media mt-auto">
                        <Col xs={2}>
                            <a className="social-media-icon" href="https://www.instagram.com/forfunlab" target="_blank"><FaInstagram /></a>
                        </Col>
                        <Col xs={2}>
                            <a className="social-media-icon" href="https://www.youtube.com/@forfunlab8049" target="_blank"><FaYoutubeSquare /></a>
                        </Col>
                        <Col xs={2}>
                            <a className="social-media-icon" href="https://twitter.com/LabForfun" target="_blank"><FaTwitterSquare /></a>
                        </Col>
                        <Col xs={2}>
                            <a className="social-media-icon" href="https://www.linkedin.com/in/for-fun-lab-4a79b9244/" target="_blank"><FaLinkedin /></a>
                        </Col>
                        <Col xs={2}>
                            <a className="social-media-icon" href="https://github.com/forfunlab" target="_blank"><FaGithubSquare /></a>
                        </Col>
                    </Row>
                </Row>
            </Col>
            <Col  md={9} className="panel right-panel fill-height fill-width">
                <AmpInstagram/>
                <Row className="fill-height fill-width justify-content-md-center">
                    <Col className="ig-col" md={4}>
                        <amp-instagram data-shortcode="Cf9K4vqgQ3N" width="100%" height="100%"/>
                    </Col>
                    <Col className="ig-col" md={4}>
                        <amp-instagram data-shortcode="CtoU-P7gR4F" width="100%" height="100%"/>
                    </Col>
                    <Col className="ig-col" md={4}>
                        <amp-instagram data-shortcode="ClNvWSTDxV2" width="100%" height="100%"/>
                    </Col>
                  </Row>
            </Col>
          </Row>
        </Container>
      );
  }
}