import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import { FaInstagram, FaYoutubeSquare, FaTwitterSquare, FaLinkedin, FaGithubSquare } from "react-icons/fa";
import Carousel from 'react-bootstrap/Carousel';
import AmpInstagram from '../../../components/AmpInstagram';

import { useState } from 'react';

import './styles.css';

export default class HomeSmallSize extends Component {
  render() {
      return(
        <Container fluid className="fill-height">
        <Navbar fixed="top" className="customFixedNavBar" bg="dark" variant="dark">
           <Navbar.Brand>
               <img
                alt=""
                src="/ForFunLab_logo.png"
                width="40"
                height="40"
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>

          </Navbar>
          <Row className="justify-content-center intro">
            <div className="disclaimer">
                Remember: it's just for fun :)
            </div>
          </Row>
            <Row className="justify-content-center ig-row">
                <AmpInstagram/>
                <Col className="ig-col">
                    <amp-instagram data-shortcode="Cf9K4vqgQ3N" width="80%" height="100%"/>
                </Col>
            </Row>
            <Row className="justify-content-md-center ig-row">
                <Col className="ig-col">
                    <amp-instagram data-shortcode="CtoU-P7gR4F" width="80%" height="100%"/>
                </Col>
            </Row>
            <Row className="justify-content-md-center ig-row">
                <Col className="ig-col">
                    <amp-instagram data-shortcode="ClNvWSTDxV2" width="80%" height="100%"/>
                </Col>
            </Row>
            <Row className="justify-content-center social-media-small mt-auto">
                <Col xs={2}>
                    <a className="social-media-icon" href="https://www.instagram.com/forfunlab" target="_blank"><FaInstagram /></a>
                </Col>
                <Col xs={2}>
                    <a className="social-media-icon" href="https://www.youtube.com/@forfunlab8049" target="_blank"><FaYoutubeSquare /></a>
                </Col>
                <Col xs={2}>
                    <a className="social-media-icon" href="https://twitter.com/LabForfun" target="_blank"><FaTwitterSquare /></a>
                </Col>
                <Col xs={2}>
                    <a className="social-media-icon" href="https://www.linkedin.com/in/for-fun-lab-4a79b9244/" target="_blank"><FaLinkedin /></a>
                </Col>
                <Col xs={2}>
                    <a className="social-media-icon" href="https://github.com/forfunlab" target="_blank"><FaGithubSquare /></a>
                </Col>
            </Row>
        </Container>
      );
  }
}